import { template as template_6bfb2491c5584647959d9f26754eb07a } from "@ember/template-compiler";
const FKLabel = template_6bfb2491c5584647959d9f26754eb07a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
