import { template as template_128d8360a5514b17829fc939e34f3c5b } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_128d8360a5514b17829fc939e34f3c5b(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
