import { template as template_465e3965d7784ceebbbc065bfcd93c16 } from "@ember/template-compiler";
const FKText = template_465e3965d7784ceebbbc065bfcd93c16(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
